import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import YouTube from 'react-youtube'

import { TransitionMixin, media } from '../helpers'
import Layout from '../components/layout'
import SEO from '../components/seo'

const opts = {
  height: '390',
  width: '640',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
}

const YoutubeBackground = styled.div`
  display: none;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(42, 42, 42, 0.8);
  z-index: 100;
  opacity: 0;
  ${TransitionMixin('.25s')}

  &.show {
    display: block;
  }

  &.fadeIn {
    opacity: 1;
  }

  .close-wrap {
    position: absolute;
    top: 7vh;
    left: 47vw;
    ${media.small`display: flex;top: 15vh; right: 15vw; left: auto;`};
    button {
      background: transparent;
      border: none;

      &:hover {
        cursor: pointer;
      }

      &:active,
      &:focus {
        outline: 0;
      }
    }
    i {
      color: #fff;
      font-size: 2.5em;
    }
    .fa-times-thin:before {
      content: '\00d7';
    }
  }

  .player {
    display: flex;
    height: 100%;
    span {
      flex: 1;
      justify-content: center;
      text-align: center;
      align-self: center;
    }
    iframe {
      max-width: 700px;
      width: 100%;
      height: 400px;
    }
  }
`

class YoutubePlayer extends React.Component {
  state = {
    player: '',
  }
  _onReady = event => {
    console.log(event.target)
    // access to player in all event handlers via event.target
    event.target.pauseVideo()

    this.setState({
      player: event.target,
    })
  }

  pauseOnClose = () => {
    this.props.closeVideo()
    this.state.player.pauseVideo()
  }

  render() {
    const { videoId, isActive, closeVideo } = this.props

    return (
      <YoutubeBackground
        id="yt-player"
        style={isActive ? { display: 'block' } : { display: 'none' }}
      >
        <div className="close-wrap">
          <button onClick={this.pauseOnClose}>
            <i className="fa fa-times-thin fa-2x" aria-hidden="true" />
          </button>
        </div>
        <div className="player">
          <YouTube videoId={videoId} opts={opts} onReady={this._onReady} />
        </div>
      </YoutubeBackground>
    )
  }
}

const HalfBannerWrap = styled.div`
  position: relative;
  padding-top: 60px;

  ${media.small`padding-top: 0px;`};

  .gatsby-image-wrapper {
    overflow: hidden;
    max-height: 62vh;
  }

  .overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(42, 42, 42, 0.65);
  }

  .caption {
    position: absolute;
    top: 24vh;
    width: 100%;
    text-align: center;

    ${media.small`top: 32vh;`};

    h1 {
      color: #fff;
      max-width: 700px;
      margin: 0 auto;
      font-size: 3.25rem;

      span {
        display: block;
        font-size: 17px;
        margin-top: 10px;
        font-weight: 300;
        line-height: 20px;
      }
    }
  }
`

const WorkWrap = styled.div`
  .grid {
    padding: 20px 0px;
    margin-left: 20px;
    ${media.small`display: flex;`};

    .video-grid {
      display: flex;
      flex-wrap: wrap;
      flex: 1;

      &::after {
        content: ' ';
        flex: 1 1 43%;
      }
    }

    h4 {
      flex: 1 1 100%;
    }

    .link-list {
      flex: 1;
      padding-right: 20px;

      p {
        font-size: 16px;
      }
    }

    .video-wrap {
      flex: 1 1 100%;
      max-width: 455px;
      box-sizing: border-box;
      margin-right: 20px;
      margin-bottom: 20px;
      top: 32vh;
      ${media.small`margin-right: 20px; flex: 1 1 40%;`};
      button {
        background: transparent;
        border: none;
        width: 100%;
        padding: 0px;

        &:active,
        &:focus {
          outline: 0;
        }

        .thumbnail {
          position: relative;
          max-height: 250px;
          overflow: hidden;
          .overlay {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            background: rgba(42, 42, 42, 0.4);
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            ${TransitionMixin('.25s')};
            ${media.small`opacity: 0;`};
          }

          &:hover {
            cursor: pointer;
            .overlay {
              opacity: 1;
            }
          }
          .thumbnail-content {
            top: 0;

            i {
              color: #fff;
              margin: 0px;
              font-size: 70px;
            }

            span.hidden-id {
              display: none;
            }
          }
        }
        .caption {
          padding-top: 10px;
          text-align: left;

          h4 {
            font-weight: 300;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
`

class Work extends React.Component {
  state = {
    videoPlayerActive: false,
    currentVideoId: '',
  }

  openVideoPlayer = value => {
    console.log(value)
    this.setState({
      videoPlayerActive: true,
      currentVideoId: value,
    })

    setTimeout(function() {
      document.querySelector('#yt-player').classList.add('fadeIn')
    }, 100)
  }

  closeVideoPlayer = () => {
    this.setState({
      videoPlayerActive: false,
    })
  }

  render() {
    const { location, data } = this.props
    function createMarkup() {
      return { __html: data.wordpressPage.content }
    }
    console.log(data)
    return (
      <Layout location={location}>
        <HalfBannerWrap>
          <Img fluid={data.wbanner.childImageSharp.fluid} />
          <div className="overlay" />
          <div className="caption">
            <h1>Work</h1>
          </div>
        </HalfBannerWrap>
        <SEO
          title="Work"
          description="Through the avenues of reporting, anchoring, writing and producing the news I have gained valuable experience in journalism working for NBC Sports Bay Area, Pac-12 Networks and with the Cape Cod Baseball League."
        />
        <WorkWrap>
          <div className="grid">
            <div className="link-list">
              <h4>Articles and Podcasts</h4>
              <div dangerouslySetInnerHTML={createMarkup()} />
            </div>
            <div className="video-grid">
              <h4>Videos</h4>
              {data.allWordpressWpPortfolio.edges.map(item => {
                let portfolio = item.node
                let videoID = portfolio.acf.video_id
                return (
                  <div className="video-wrap" key={portfolio.id}>
                    <button onClick={() => this.openVideoPlayer(videoID)}>
                      <div className="thumbnail">
                        {portfolio.acf.video_thumbnail &&
                        portfolio.acf.video_thumbnail.localFile ? (
                          <Img
                            fluid={
                              portfolio.acf.video_thumbnail.localFile
                                .childImageSharp.fluid
                            }
                          />
                        ) : (
                          ''
                        )}
                        <div className="overlay">
                          <div className="thumbnail-content">
                            <i className="fa fa-play-circle" />
                            <span className="hidden-id">
                              {portfolio.acf.video_id}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="caption">
                        <h4>{portfolio.title}</h4>
                        <span />
                      </div>
                    </button>
                  </div>
                )
              })}
            </div>
          </div>
        </WorkWrap>
        <YoutubePlayer
          closeVideo={this.closeVideoPlayer}
          videoId={this.state.currentVideoId}
          isActive={this.state.videoPlayerActive}
          id="yt-player"
        />
      </Layout>
    )
  }
}

export default Work

export const query = graphql`
  query {
    wbanner: file(relativePath: { eq: "bbanner.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allWordpressWpPortfolio {
      edges {
        node {
          id
          title
          acf {
            video_id
            video_thumbnail {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    wordpressPage(title: { eq: "Work" }) {
      title
      content
      id
    }
  }
`
